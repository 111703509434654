import { Box, useTheme, Typography, Divider, IconButton } from "@mui/material";
import FormFields from "../FormFields";
import { tokens } from "../../../global/theme/tokens";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import uuid from "react-uuid";
import { updateOrCreate } from "../../special/updateOrCreate";
import ExoDialog from "../../exo/ExoDialog";
import ExoForm from "../../exo/ExoForm";
import LabeledBox from "../../special/LabeledBox";
import MoneyLabel from "../../label/MoneyLabel";
import ExoCostCenter, { costCenterIconMap } from "../../exo/ExoCostCenter";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { ProjectAddElementButton } from "../../exo/project/ProjectAddElementButton";
import * as Yup from "yup";
import RenderHTML from "../../preview/RenderHTML";
import DeleteIcon from "@mui/icons-material/Delete";
import { ExoPDFViewer } from "../../exo/ExoPDFViewer";
import { ExoFilePreview } from "../../exo/ExoFilePreview";
import PDFPreview from "../../special/PDFPreview";

const TypeReceiptPositions = ({
  updateValidationOnChange,
  dataCollection,
  errors,
  field,
  onPreventEnter,
  param,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  const { t } = useTranslation();

  const [selectedElement, setSelectedElement] = useState(null);

  useEffect(() => {
    if (dataCollection[field.key]) checkAndAddUUIDS(dataCollection[field.key]);
  }, []);

  function checkAndAddUUIDS(dataToCheck) {
    var dataWithUUIDS = dataToCheck;
    dataWithUUIDS.forEach((element) => {
      if (!element.id) element.id = uuid();
    });

    updateValidationOnChange(field.key, dataWithUUIDS);
  }

  const fields = [
    {
      label: t("Position"),
      key: "position",
      type: "text",
      colspan: "2",
    },
    {
      label: t("Cost Center"),
      type: "costCenter",
      key: "costCenter",
      colspan: "2",
    },
    {
      label: t("Tax Account"),
      key: "taxAssignment",
      type: "taxAssignment",
    },
    {
      label: t("Tax Rate"),
      key: "taxRate",
      type: "text",
      unit: "%",
    },
    {
      label: t("Price"),
      key: "amount",
      type: "money",
    },
    {
      label: `${t("Comment")} (${t("optional")})`,
      key: "note",
      type: "richText",
    },
  ];

  const formValidationSchema = Yup.object().shape({
    amount: Yup.string().required(t("Amount is required")),
    position: Yup.string().required(t("Position is required")),
    taxAssignment: Yup.object().shape({
      id: Yup.number().required(t("Tax Assignment is required")),
    }),
    taxRate: Yup.number().required(t("Tax Rate is required")),
    costCenter: Yup.object().shape({
      id: Yup.string().required(t("Cost Center is required")),
    }),
  });

  function handleChange(data) {
    setSelectedElement(null);

    const newData = updateOrCreate(dataCollection[field.key] || [], data);

    updateValidationOnChange(field.key, newData);
  }

  function handleAddElement() {
    setSelectedElement({ id: uuid(), taxRate: 19 });
  }

  function handleDeleteElement() {
    const newData = dataCollection[field.key].filter(
      (obj) => obj.id !== selectedElement.id
    );

    updateValidationOnChange(field.key, newData);
    setSelectedElement(null);
  }

  const hasFile =
    param && param.file && Boolean(Object.keys(param.file).length);

  const fileData = param ? param.file : {};

  return (
    <Box
      className="w-full flex flex-col gap-4"
      sx={{
        gridColumn: field.colspan
          ? `span ${field.colspan} / span ${field.colspan}`
          : "unset",
      }}
    >
      <Typography variant="h4">{t("Distribution")}</Typography>
      {dataCollection[field.key] &&
        dataCollection[field.key].map((element) => (
          <ReceiptPosition
            key={element.id}
            element={element}
            onClick={setSelectedElement}
          />
        ))}

      <ProjectAddElementButton
        label={t("add distribution")}
        onClick={handleAddElement}
      />

      <ExoDialog
        open={Boolean(selectedElement)}
        className={`flex flex-col-reverse lg:flex-row gap-2 h-full overflow-hidden ${
          hasFile ? "" : "justify-center"
        }`}
      >
        {hasFile && (
          <>
            {fileData.type === "application/pdf" ? (
              <PDFPreview
                src={
                  fileData.id ? fileData.path : URL.createObjectURL(fileData)
                }
              />
            ) : (
              <img
                className="w-full h-full object-contain"
                src={
                  fileData.id ? fileData.path : URL.createObjectURL(fileData)
                }
                alt={fileData.title}
              />
            )}
          </>
        )}

        <ExoForm
          header={t("Distribution")}
          className="max-h-full flex flex-col overflow-y-auto gap-4 pt-2 max-w-full min-w-[unset] w-full lg:min-w-[550px] lg:max-w-[550px]"
          fields={fields}
          validationSchema={formValidationSchema}
          startDataCollection={selectedElement}
          onSubmit={handleChange}
          onCancle={() => setSelectedElement(null)}
          noDifferences
        />
        <IconButton
          onClick={handleDeleteElement}
          color="error"
          sx={{ position: "absolute" }}
          className="top-5 right-5"
        >
          <DeleteIcon />
        </IconButton>
      </ExoDialog>
    </Box>
  );
};

export const ReceiptPosition = ({ element, onClick, noBackground }) => {
  const { t } = useTranslation();
  const { taxAssignment, costCenter, amount, position, taxRate, note } =
    element;

  return (
    <LabeledBox
      className="flex flex-col @container gap-4 w-full"
      onClick={() => onClick(element)}
      noBackground={noBackground}
    >
      <Box className="flex justify-between flex-col @md:flex-row @md:gap-6">
        <Box className="flex gap-4 items-center justify-between @md:justify-start">
          <Box>
            <Typography fontWeight={600}>{position}</Typography>
            {taxAssignment && (
              <Typography
                className="monospace"
                variant="h6"
              >{`${taxAssignment.account} ${taxAssignment.label}`}</Typography>
            )}
          </Box>

          <ArrowForwardIcon className=" opacity-40" />

          {costCenter && (
            <Box className="flex flex-col justify-center items-center">
              {costCenterIconMap[costCenter.type]}
              <Typography>{costCenter.name}</Typography>
            </Box>
          )}
        </Box>
        <Box>
          <Typography
            fontWeight={600}
            variant="h4"
            color={parseFloat(amount) > 0 ? "error" : "success"}
          >
            <MoneyLabel money={amount} />
          </Typography>
          <Typography
            variant="h6"
            className="monospace"
          >{`${taxRate} %`}</Typography>
        </Box>
      </Box>
      {note && (
        <>
          <Divider />
          <Box>
            <Typography variant="h6">{t("Note")}</Typography>
            <RenderHTML HTML={note || " "} />
          </Box>
        </>
      )}
    </LabeledBox>
  );
};

export default TypeReceiptPositions;
