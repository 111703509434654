import { Box, Dialog, useTheme } from '@mui/material';
import { tokens } from '../../global/theme/tokens';
import ExoTable from '../../components/exo/ExoTable';
import { API_GET, DBRequest, useConfig } from '../../api/api';
import { useEffect, useState } from 'react';
import {
  ExoTableToolbar,
  ExoTableToolbarItem,
} from '../../components/exo/ExoTableToolbar';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import NewProduct from '../../components/new/NewProduct';
import ExpandableInfo from '../../components/special/ExpandableInfo';
import InventoryIcon from '@mui/icons-material/Inventory';
import Header from '../../components/special/Header';
import BookmarksIcon from '@mui/icons-material/Bookmarks';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { Categories } from '../../components/special/Categories';
import ExtendProduct from './ExtendProduct';
import { updateOrCreate } from '../../components/special/updateOrCreate';
import useFetch from '../../api/useFetch';
import ExoDialog from '../../components/exo/ExoDialog';
import { hasPermissions } from '../../auth/hasPermissions';

const Products = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  const { t } = useTranslation();

  const { apiData, isLoading, setApiData } = useFetch('products');

  // table
  const Columns = [
    {
      header: t('Nr'), // header of column
      key: 'id', // key of column in row-object
      thAlign: 'left', // header text alignment
      tdAlign: 'left', // cell text alignment
      sortable: true, // enable sort function for column
      cell: ({ row }) => (
        <Box className="monospace whitespace-nowrap">{row.nr}</Box>
      ), // custom xml for cells in the column. you get the row as object
    },
    {
      header: t('Name'), // header of column
      key: 'name', // key of column in row-object
      thAlign: 'left', // header text alignment
      tdAlign: 'left', // cell text alignment
      sortable: true, // enable sort function for column
      width: '100%', // width of column
      cell: ({ row }) => (
        <ExpandableInfo
          name={row.name}
          description={row.description}
          icon={<InventoryIcon />}
        />
      ),
    },
    {
      header: t('Categories'), // header of column
      key: 'productCategories', // key of column in row-object
      thAlign: 'left', // header text alignment
      tdAlign: 'left', // cell text alignment
      width: '200px', // width of column
      hiddenMobile: true, // hides the column on mobile
      cell: ({ row }) => <Categories data={row.productCategories} />, // custom xml for cells in the column. you get the row as object
    },
    {
      header: t('Unit'), // header of column
      key: 'unit', // key of column in row-object
      thAlign: 'left', // header text alignment
      tdAlign: 'left', // cell text alignment
      sortable: true, // enable sort function for column
      hiddenMobile: true,
      width: '60px', // width of column
    },
    {
      header: t('Tax Rate'), // header of column
      key: 'taxRate', // key of column in row-object
      thAlign: 'right', // header text alignment
      tdAlign: 'right', // cell text alignment
      sortable: true, // enable sort function for column
      cell: ({ row }) => <Box className="monospace">{row.taxRate} %</Box>, // custom xml for cells in the column. you get the row as object
      hiddenMobile: true,
      width: '100px', // width of column
    },
    {
      header: t('Price'), // header of column
      key: 'price', // key of column in row-object
      thAlign: 'right', // header text alignment
      tdAlign: 'right', // cell text alignment
      sortable: true, // enable sort function for column
      width: '130px', // width of column
      hiddenMobile: true,
      cell: ({ row }) => <Box className="monospace">{row.price} €</Box>, // custom xml for cells in the column. you get the row as object
    },
  ];

  // new product dialog
  const [open, setOpen] = useState(false);
  function handleClose() {
    setOpen(false);
  }

  function handleCreateProduct(newProduct) {
    setApiData(updateOrCreate(apiData, newProduct));
    handleClose();
  }

  // actions
  const [productToEdit, setProductToEdit] = useState(null);
  function handleClickEdit(data) {
    setProductToEdit(data);
    setOpen(true);
  }

  return (
    <>
      <Header title={t('Products')} />
      <ExoTableToolbar>
        <ExoTableToolbarItem
          text={t('New Product')}
          icon={<PlaylistAddIcon />}
          onClick={() => setOpen(true)}
        />
        {hasPermissions('product-category', 'write') && (
          <NavLink to="/categories/product">
            <ExoTableToolbarItem
              text={t('Categories')}
              icon={<BookmarksIcon />}
            />
          </NavLink>
        )}
      </ExoTableToolbar>
      <ExoTable
        data={apiData}
        isLoading={isLoading}
        columns={Columns}
        extendElement={({ row, onUpdate, onDelete }) => (
          <ExtendProduct row={row} onUpdate={onUpdate} onDelete={onDelete} />
        )}
      />

      <ExoDialog open={open} limitWidth>
        <NewProduct onClose={handleClose} onCreate={handleCreateProduct} />
      </ExoDialog>
    </>
  );
};

export default Products;
